<template>
  <div class="flex justify-center">
    <div class="flex-grow bg-white shadow py-12 md:px-24 mt-12 max-w-2xl rounded">
      <font-awesome-icon
          :icon="['far', 'badge-check']"
          class="text-green-500 text-5xl"
        />
      <h2 class="mt-4">Registration Successful</h2>
      <p class="mt-6">Thanks for registering for Guthrie Soccer!</p>
      <p class="text-xs font-semibold mt-2 text-gray-600">You will receive a receipt email from Stripe soon.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentComplete',
  mounted() {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("id")) {
      // update airtable with payment completed
      this.$store.dispatch('syncPaymentStatus', { status: "Paid", id: query.get("id"), amount: Number(query.get("amount"))/100 })
      // set registration complete
      this.$store.dispatch('syncRegistrationStatus', { status: 'Registration Complete', id: query.get("id") })
    }

    if (query.get("promoCodeID") && query.get("id")) {
      this.$store.dispatch('syncPromoCodeStatus', { householdID: query.get("id"), promoCodeID: query.get("promoCodeID") })
    }
  },
}
</script>
